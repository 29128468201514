(function ($) {
  $(window).load(function () {
    // owl-carousel
    $(".owl-carousel:not(.slider)").owlCarousel({
      items: 1,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: "fadeIn",
      animateOut: "fadeOut",

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: false,
    });

    // slider
    $(".slider:not(.news-slider)").owlCarousel({
      items: 1,
      margin: 15,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1.2,
        },
        576: {
          items: 2,
        },
        768: {
          items: 4,
        },
        992: {
          items: 4,
        },
        1200: {
          items: 4,
        },
      },
    });

    // news slider
    const $carousel = $(".slider.news-slider");
    let itemCount = $carousel.children().length;
    if (device.mobile()) {
      itemCount = 4;
    }
    $(".slider.news-slider").owlCarousel({
      items: 1,
      margin: 15,
      loop: itemCount > 3,

      // autoplay
      autoplay: itemCount > 3,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3,
        },
      },
    });

    // form-sent
    setTimeout(() => {
      var locationSearch = "" + document.location.search;
      if (
        (-1 === locationSearch.indexOf("form[sent]") &&
          -1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
        -1 === document.location.hash.indexOf("#form")
      ) {
        return;
      }

      var $headerHeight = $(".header");
      var $message = $(".form-success-message");

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $("html").scrollTop();
      var newScrollTop =
        $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({
        scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
      });
    }, 500);
  });

  $(document).ready(function () {
    // sticky
    require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");

    // fancybox
    $("a[data-fancybox]").fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close",
      ],
    });

    // scroll-to-content
    $(".js-3w-scroll-to-content").on("click", function (event) {
      event.preventDefault();
      var identifier = $(this).attr("data-target");
      var $element = $(identifier);

      if ($element) {
        $("html, body").animate({ scrollTop: $element.offset().top }, 500);
      }
    });
  });
})(jQuery);

// Slider offset

function sliderOffset() {
  const container = document.querySelector(".omgeving-section-two .container");
  const dots = document.querySelectorAll(".faciliteiten-slider .owl-dots");
  const containerMarginLeft = window
    .getComputedStyle(container)
    .getPropertyValue("margin-left");
  const sliderOffset = [
    document.querySelector(".faciliteiten-section .container-fluid .column"),
    document.querySelector(".omgeving-section-two .container-fluid .column"),
  ];
  let slidepos = [0, 1];
  for (i in slidepos) {
    sliderOffset[i].style.paddingLeft = containerMarginLeft;
    dots[i].style.paddingRight = containerMarginLeft;
  }
}
if (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
} else {
  window.addEventListener("load", sliderOffset);
  window.addEventListener("resize", sliderOffset);
}

// profile
// <moncompte-trigger><i class="fa-user fas"></i></moncompte-trigger>
let targetList = document.querySelector("ul.social-header");
let newItem = document.createElement("li");

let liTitle = "Mijn omgeving";

var browserLanguage = navigator.language;

if (
  browserLanguage === "fr-FR" ||
  browserLanguage === "fr-BE" ||
  browserLanguage === "fr"
) {
  liTitle = "Mon compte";
}
if (browserLanguage === "de-DE" || browserLanguage === "de") {
  liTitle = "Mein konto";
}
if (
  browserLanguage === "en-EN" ||
  browserLanguage === "en-US" ||
  browserLanguage === "en"
) {
  liTitle = "My account";
}

newItem.title = liTitle;
newItem.style.cursor = "pointer";

newItem.innerHTML = `<moncompte-trigger><i class="fa-user fas"></i></moncompte-trigger>`;
targetList.appendChild(newItem);

// menu overlay remove transparant on menu toggle
let menuButton = document.querySelector(".navbar-toggler");
let header = document.querySelector("header");

menuButton.addEventListener("click", () => {
  if (document.body.classList.contains("hide")) {
    document.body.classList.remove("hide");
    header.classList.remove("fullBg");
  } else {
    document.body.classList.add("hide");
    header.classList.add("fullBg");
  }
});

document.addEventListener("click", function (event) {
  var targetElement = event.target;
  let header = document.querySelector("header");
  let menuOverlay = document.querySelector(".menu-overlay");
  let toggler = document.querySelector(".navbar-toggler");

  var isClickedInside = header.contains(targetElement);
  if (!isClickedInside) {
    if (menuOverlay.classList.contains("show")) {
      menuOverlay.classList.toggle("show");
      document.body.classList.toggle("hide");
      toggler.ariaExpanded = "false";
    }
  }
});

// news ticker
let ticker = document.querySelector("section.news-ticker-section ul.ticker");
let tickerDiv = document.querySelector(
  "section.news-ticker-section div.ticker"
);

if(ticker.childElementCount > 1){
  ticker.classList.add("active-mobile");
}


if (ticker && tickerDiv) {
  let tickerWidth = ticker.offsetWidth;

  let windowWidth = window.innerWidth;
  if (tickerWidth > windowWidth) {
    let clone = ticker.cloneNode(true);
    clone.classList.add("clone")
    clone.classList.add("active");

    tickerDiv.appendChild(clone);

    ticker.classList.add("active");
  }
}
